import { graphql } from 'gatsby';
import React, { useEffect, useState, useRef } from 'react';

import '../styles/main.scss';

import { StaticImage } from 'gatsby-plugin-image';


import SEO from '../components/seo';
import '../styles/layout/studio.scss';

import Layout from '../components/Layout';

import VideoShaderCanvas from '../components/VideoShaderCanvas';
import PixelatedShaderImageSequence from '../components/PixelatedShaderImageSequence';

import {
  Link,
  Trans,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
/*
Import Treejs and loaders
*/

import Icon from '../components/Icon';
import GiveContentTrans from '../components/GiveContentTrans';

import { ListTLink, animateObjects, newContent } from '../components/Layout';
import MagneticButton from '../components/MagneticButton';




const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

// markup
// const IndexPage = ({ data }) => {

function AboutPage() {
  const { t } = useTranslation();
  const { languages, originalPath, i18n } = useI18next();

  const langCode = i18n.language;



 

  /*
  const sizes = {
    // width: window.innerWidth,
    // height: window.innerHeight,
    width: tanelref.current.offsetWidth,
    height: tanelref.current.offsetHeight,
  };
  */


  



  

 
  useEffect(() => {
    //  An effect function must not return anything besides a function
    if (typeof navigator !== 'undefined' && isMobile()) {
      return function () {};
    }

  }, []);

  //  when on mobile- use rendered images instead
  let showStaticImage = false;
  if (typeof navigator !== 'undefined' && isMobile()) {
    showStaticImage = true;
  }

  return (
    <Layout>
    <main className="about-page">
      <GiveContentTrans language="et">
        <SEO
          title="Digiagentuuri lugu"
          description="Parajalt väike aga võimekas digiagentuur Eestis. Loe lähemalt miks Give on ideaalne digipartner kodulehede ja UI/UX ning logo disaini projektidele."
          image="/pagecover.jpg"
          structuredData={{
            "@type": "WebPage",
            "name": "Give Digiagentuuri lugu",
            "description": "Parajalt väike aga võimekas digiagentuur Eestis. Loe lähemalt miks Give on ideaalne digipartner kodulehede ja UI/UX ning logo disaini projektidele.",
            "url": "https://give.ee/agentuur/", // or use a dynamic variable
            "inLanguage": "et"
          }}
        />
      </GiveContentTrans>
      <GiveContentTrans language="en">
        <SEO
          title="Your Go-To Digital Agency in Estonia"
          description="Small yet powerful digital powehouse from Estonia. Discover our story and find out why we are excellent partner for websites, UI/UX and logo design!"
          image="/pagecover-en-default.jpg"
          structuredData={{
            "@type": "WebPage",
            "name": "Give Your Digital Agency",
            "description": "Small yet powerful digital powehouse from Estonia. Discover our story and find out why we are excellent partner for websites, UI/UX and logo design!",
            "url": "https://give.ee/en/agency/", // or use a dynamic variable
            "inLanguage": "en"
          }}
        />
      </GiveContentTrans>
      <canvas className="avatars" id="c" />
      <section className="hero">
        <GiveContentTrans language="et">
          <h1>Give. Väike, aga võimekas digiagentuur Eestis</h1>
          <p>
            Disainime kasutajasõbralikke kodulehti, digitaalseid SaaS tooteid ja
            mobiiliaplikatsioone, mis näevad head välja ning toovad tulemusi.
            Peame oluliseks kaasaegset disaini ja kasutusmugavust. Kujundame unikaalseid ja
            ajas vastupidavaid logosid ning brände ja teeme graafilist disaini, mis edastab
            soovitud sõnumi. Me naudime oma tööd ja soovime iga projektiga saada
            veel paremaks.
          </p>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h1>Give. A small but mighty Estonian agency</h1>
          <p>
            UI/UX design for modern websites, digital dashboards, SaaS products, and mobile
            applications that look great and deliver results. We prioritize
            clarity and user-friendliness, creating unique and durable brands
            and graphics that convey your desired message. We love what we do
            and aim to get even better with every project.
          </p>
          <p>
            Here's a fun fact: Estonia has the most startups per capita in
            Europe
          </p>
        </GiveContentTrans>
      </section>
      <div className="hero-image">
        <StaticImage
          src="../assets/give-stuudio1.jpg"
          alt="Give stuudio"
          layout="fullWidth"
        />
      </div>
      <section className="past-clients section-margin">
        <GiveContentTrans language="et">
          <h2>Meie tööd on usaldanud</h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>Our work has been trusted by...</h2>
        </GiveContentTrans>
        <div className="logo-row mobile-hide def-top-margin">
        <Icon name="giveclientsmobile" />
        </div>
        <div className="logo-row-mobile mobile-show def-top-margin">
        <Icon name="giveclientsmobile" />
        </div>
      </section>

      <div className="img-100 section-margin">
        <StaticImage
          src="../assets/give-stuudio2.jpg"
          alt="Give stuudio"
          layout="fullWidth"
        />
      </div>

      <section className="give-story inner-grid section-margin ">
        <GiveContentTrans language="et">
          <h2>Digiagentuuri biograafia</h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>Digital agency story</h2>
        </GiveContentTrans>

        <div className="col page-copy def-top-margin">
          <GiveContentTrans language="et">
            <p>
              Give digiagentuur on asutatud 2010. aastal Tallinnas visiooniga pakkuda oma
              klientidele parimaid kodulehti, UI/UX disaini, graafilist disaini ja firmagraafikat. Oleme töötanud nii maailma
              juhtivamate tehnoloogiaettevõtetega kui ka kohalike tippudega.
              Koostöös teiste tippspetsialistidega oleme õppinud, kuidas digimaailm toimib ja mida
              kasutajad hindavad. Armastame väljakutseid ja pöörame igale
              projektile maksimaalset tähelepanu.
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <p>
              Give was founded in Tallinn in 2010 with a vision to provide the
              best web and mobile design solutions for our clients. We've worked
              with leading technology companies worldwide as well as with local
              top players. Collaborating with other top specialists, we've
              acquired broad knowledge of how the digital world works and what
              users value. We love challenges and give each project our maximum
              attention.
            </p>
          </GiveContentTrans>
        </div>
        <div className="col page-copy def-top-margin">
          <GiveContentTrans language="et">
            <p>
              Suhtleme otsekoheselt ja kiiresti. Leiame, et parima tulemuse saab
              ainult siis, kui digiagentuur ja tellija on vahetus kontaktis. Give
              unikaalne DNA seisneb selles, et iga mees on oma projekti juht.
              Meil on väljakujunenud protsessid, kuidas saavutada tulemusi, kuid
              oma unikaalse struktuuri tõttu oleme paindlikud töötama eri liiki
              meeskondades vastavalt nende soovidele.
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <p>
              We communicate directly and quickly, believing that the best
              results are achieved when the worker and the client are in direct
              contact. Give's unique DNA lies in each person being the project
              leader. While we have established processes for achieving results,
              our unique structure allows us to be flexible in working with
              different types of teams according to their preferences.
            </p>
          </GiveContentTrans>
        </div>
      </section>

      <section className="give-crew inner-grid section-margin">


        <GiveContentTrans language="et">
          <h2>Digiagentuuri meeskond</h2>

     

        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>Digital Agency Team</h2>
        </GiveContentTrans>


        <div className="col page-copy def-top-margin">
          <GiveContentTrans language="et">
            <p>
              360 kraadine meeskond. Meie meeskonna tugevus on kogemus. Back ja front-end arendus keerulistele meditsiini süsteemidele ja panganduses. 
              Disainer kes päriselt oskab kodulehe teha valmis. On disaininud kõike alates sotsiaalmeedia postitustest kuni veebitarkvara lahendusteni mis mõeldud suurklientidele. 
              Teame protsesse ja näeme asjade tervikpilti. Kus ise jääme hätta seal kasutame tippspetsialistide abi. 
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <p>
              360 degree team. We 
            </p>
          </GiveContentTrans>
        </div>

        <div className="column page-copy">
          <div className="person-meta">
            {showStaticImage && (
              <div className="avatar">
                <div className="avatar-item">
                  <StaticImage
                    src="../assets/janar3600000.png"
                    alt="Janar Jürisson"
                    layout="fullWidth"
                  />
                </div>
              </div>
            )}
            {!showStaticImage && (
         <div className="avatar">
         <div className="avatar-item">
         <PixelatedShaderImageSequence name="janar" />
         </div>
       </div>
            )}

            <GiveContentTrans language="et">
              <h3>Janar Jürisson</h3>
              <span>Arendaja</span>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <h3>Janar Jürisson</h3>
              <span>Developer</span>
            </GiveContentTrans>
          </div>
        </div>
        <div className="column page-copy">
          <div className="person-meta">
            {showStaticImage && (
              <div className="avatar">
                <div className="avatar-item">
                  <StaticImage
                    src="../assets/tanel3600000.png"
                    alt="Tanel Kaarel Sepp"
                    layout="fullWidth"
                  />
                </div>

      


              </div>
            )}
            {!showStaticImage && (
               <div className="avatar">
               <div className="avatar-item">
                  <PixelatedShaderImageSequence name="tanel" />
               </div>
             </div>


        
            )}

            <GiveContentTrans language="et">
              <h3>Tanel Kaarel Sepp</h3>
              <span>UI/UX Disainer</span>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <h3>Tanel Kaarel Sepp</h3>
              <span>UI/UX Designer</span>
            </GiveContentTrans>
          </div>
        </div>
        <div className="column page-copy ">
          <div className="person-meta">
            {showStaticImage && (
              <div className="avatar" >
                <div className="freelancer-avatar avatar-item">
                  <StaticImage
                    src="../assets/freelancer3600000.png"
                    alt="Freelancer"
                    layout="fullWidth"
                  />
                </div>
              </div>
            )}
            {!showStaticImage && (
             <div className="avatar" >
                <div className="freelancer-avatar avatar-item">
                <PixelatedShaderImageSequence name="freelancer" />
                </div>
              </div>
            )}

            <GiveContentTrans language="et">
              <h3>Soovid liituda?</h3>
              <span>Teeme koostööd tipptasemel vabakutselistega. Anna endast märku!</span>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <h3>Interested in joining us?</h3>
              <span>We collaborate with top-quality freelancers. Let us know!</span>
            </GiveContentTrans>
          </div>
        </div>
      </section>

      <GiveContentTrans language="et">
        <section className="offeredservices-section">
          <div className="title-block">
          <h2>Kuidas saame sind aidata?</h2>

          <ListTLink
              to="/teenused/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Teenused"
                magneticDistance="150"
                magneticSpeed="0.4"
              />
            </ListTLink>

          </div>
          <div className="content">
            <ul>
              <li>
            
                <ListTLink
              to="/teenused/kodulehed/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
            <h4>Kodulehtede valmistamine</h4>
            </ListTLink>
              </li>
              <li>
       
                <ListTLink
              to="/teenused/kodulehed/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
                    <h4>Kodulehtede disain</h4>
            </ListTLink>
              </li>
              <li>
              
                <ListTLink
              to="/teenused/ui-ux-disain/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
               <h4>UI/UX disain</h4>
            </ListTLink>
              </li>
              <li>
                <h4>Prototüüpimine</h4>
              </li>
              <li>
                <h4>Digitaalsed kampaaniad</h4>
              </li>
              <li>
                <h4>Veebi- ja veebitarkvara arendus</h4>
              </li>
              <li>
       
                <ListTLink
              to="/teenused/logo-kujundamine/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <h4>Logo ja firmastiili disainimine</h4>
            </ListTLink>
              </li>
              <li>
              
                <ListTLink
              to="/teenused/logo-kujundamine/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
                <h4>Brändiraamatud</h4>
            </ListTLink>
              </li>
              <li>
               

                <ListTLink
              to="/teenused/mobiilirakendused/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
                     <h4>Mobiiliäppide disain</h4>
            </ListTLink>
              </li>
              <li>
            
                <ListTLink
              to="/teenused/graafiline-disain/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
                    <h4>Reklaam</h4>
            </ListTLink>
              </li>
  
          <li>
            
            <ListTLink
          to="/teenused/graafiline-disain/"
          exit={{
            length: 1,
            zIndex: 2, // exit default is 0
            trigger: ({ exit, e, node }) => animateObjects(exit, node),
          }}
          entry={{
            delay: 0.5,
            length: 0.6,
            trigger: ({ entry, node }) => newContent(node),
          }}
        >
                <h4>Disainiagentuuri tööd</h4>
        </ListTLink>
          </li>
              <li>
                <h4>Veebianimatsioonid</h4>
              </li>
              <li>
                <h4>HTML/CSS</h4>
              </li>
              <li>
                <h4>GSAP</h4>
              </li>
              <li>
                <h4>Webflow</h4>
              </li>
              <li>
                <h4>Wordpress</h4>
              </li>
              <li>
                <h4>Framer</h4>
              </li>
              <li>
 

                <ListTLink
              to="/teenused/graafiline-disain/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
                <h4>Graafiline disain</h4>
            </ListTLink>


              </li>
              <li>
                <h4>Disaini nõustamine</h4>
              </li>
              <li>
                <h4>UI/UX disaini analüüs</h4>
              </li>
              <li>
      

                <ListTLink
              to="/teenused/maandumislehed/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
                          <h4>Maandumislehtede disain</h4>
            </ListTLink>
              </li>
            </ul>
          </div>
        </section>
      </GiveContentTrans>
      <GiveContentTrans language="en">
        <section className="offeredservices-section">
   

          <div className="title-block">
          <h2>What we do</h2>

          <ListTLink
              to="/services/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <MagneticButton
                addionalClasses="cta"
                buttonLabel="Services"
                magneticDistance="150"
                magneticSpeed="0.4"
              />
            </ListTLink>

          </div>

          <div className="content">
            <ul>
              <li>
              

                <ListTLink
              to="/services/websites/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <h4>Website design</h4>
            </ListTLink>

              </li>
              <li>
                <h4>Digital campaigns</h4>
              </li>
              <li>
                

                <ListTLink
              to="/services/websites/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <h4>Web and software development</h4>
            </ListTLink>
              </li>
              <li>
        
                <ListTLink
              to="/services/ui-ux-design/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
                     <h4>UI/UX design</h4>
            </ListTLink>
              </li>
              <li>
                <h4>Prototyping</h4>
              </li>
              <li>
                
                <ListTLink
              to="/services/brand-identity/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              <h4>Logos&corporate identity design</h4>
            </ListTLink>
              </li>
              <li>
                <h4>Mobile app design</h4>
              </li>
              <li>
                <h4>Advertising</h4>
              </li>
              <li>
               

                <ListTLink
              to="/services/websites/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
               <h4>Web animations</h4>
            </ListTLink>
              </li>
              <li>
                <h4>HTML/CSS</h4>
              </li>
              <li>
                <h4>GSAP</h4>
              </li>
              <li>
                <h4>Webflow</h4>
              </li>
              <li>
                <h4>Wordpress</h4>
              </li>
              <li>
                <h4>Framer</h4>
              </li>
              <li>
                

                <ListTLink
              to="/services/graphic-design/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
               <h4>Graphic design</h4>
            </ListTLink>
              </li>
              <li>
                <h4>Design consulting</h4>
              </li>
              <li>
                <h4>UI/UX design reviews</h4>
              </li>
            </ul>
          </div>
        </section>
      </GiveContentTrans>
    </main>
    </Layout>
  );
}

export default AboutPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
